export const getText = {
  methods: {
    getText(text) {
      if (text.constructor === Array) {
        return text[0];
      }
      return text;
    },
  },
};
