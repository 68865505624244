<template>
  <div class="public">
    <div class="cover">
      <img class="waiting" v-if="load" src="@/assets/loading.gif" />
      <h1 class="loading" v-if="load">Spiel wird gestartet...</h1>

      <img
        id="disc"
        v-if="!load"
        :class="{ inject: discchange }"
        src="@/assets/diskette.png"
      />
    </div>
    <div class="menu">
      <div class="install"></div>
      <div class="text">
        <span class="install" id="text">{{ text }}</span
        ><span v-if="this.t != null" class="install" id="timeLeft">
          ({{ timeLeft }})</span
        >
      </div>
      <div
        id="progress"
        :class="{ expand: installed }"
        :style="{ width: progress }"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tabs",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      timer: undefined,
      t: 0,
      text: "Spiel wird installiert...",
      installed: false,
      discchange: false,
      load: false,
    };
  },
  computed: {
    ...mapGetters(["loading", "time"]),
    progress() {
      if (this.t == 0 || this.t == null) {
        return "100%";
      }
      let progress =
        (100 / this.inSeconds(this.time)) *
        (this.inSeconds(this.time) - this.t);
      return progress + "%";
    },
    timeLeft() {
      let text =
        this.inMinutes(this.t) >= 1
          ? this.inMinutes(this.t) + "m verbleibend"
          : "< 1m verbleibend";

      return text;
    },
  },
  mounted() {
    this.t = this.inSeconds(this.time);
    this.setTimer();
    //  document.addEventListener("keydown", this.nextItem);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    setTimer() {
      const self = this;
      if (self.t > 0) {
        self.timer = setTimeout(function () {
          self.t--;
          self.setTimer();
        }, 1000);
      } else {
        this.t = null;
        this.text = "Installation erfolgreich!";
        this.done();
      }
    },
    inMinutes(seconds) {
      return Math.round(seconds / 60);
    },
    inSeconds(minutes) {
      return Math.round(minutes * 60);
    },
    done() {
      const self = this;

      self.timer = setTimeout(function () {
        self.installed = true;
        self.inject();
      }, 500);
    },
    inject() {
      const self = this;

      self.timer = setTimeout(function () {
        self.discchange = true;
        self.loader();
      }, 2000);
    },
    loader() {
      const self = this;
      self.timer = setTimeout(function () {
        self.load = true;
        self.text = "";
        self.start();
      }, 2500);
    },
    start() {
      const self = this;
      self.timer = setTimeout(function () {
        self.$store.commit("install");
      }, 5000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";
@import "../scss/_shared_private.scss";

.cover {
  background-image: url("../assets/bg_full.jpg");
  background-size: cover;
}
.public {
  height: $height-full;
  width: $width;
}
.menu {
  position: absolute;
  top: $height;
  height: $menu-height;
  width: $width;
  background-image: url("../assets/bg_full.jpg");
  background-size: cover;
}
$install: $margin-mid * 2.5;
div.install {
  height: $install;
  width: 100%;

  background: black;
}
#progress {
  top: 0;
  height: $install;
  width: 0%;

  position: absolute;
  background: #e5007e;
}
$disc: $height / 1.3;
#disc {
  height: $disc;
  width: auto;
  filter: drop-shadow(1px 5px 5px rgba(0, 0, 0, 0.55));
  z-index: 100;
}
.text {
  top: 0;
  height: $install;
  width: 100%;
  position: absolute;
  z-index: 100;
  text-align: center;
}
$h1: $install * 0.6;
span.install {
  text-align: center;
  flex-direction: column;
  font-size: $h1;
  line-height: $install;

  font-family: lores-15-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  margin: 0px;
}

.expand {
  height: $height-full !important;
  transition: all 3s;
  transform: translateY(-$height);
}
.inject {
  transition: all 2s ease-in;
  transform: translateY(-$height);
}
.waiting {
  z-index: 100;
}
h1.loading {
  z-index: 100;
  flex-direction: column;
  font-size: $tip;
  font-family: lores-15-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}
</style>
