import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    chapters: {
      1: {
        text: "Büro",
        active: true,
        music: {
          c0: "after_call.mp3",
          c15: "_.mp3",
          c23: "call_ring.mp3",
          c17: "cliffhanger.mp3",
          c21: "ritter.mp3",
          c22: "sini_mueter.mp3",
          c14: "gespraech_konrad.mp3",
          c13: "schrank_geoeffnet.mp3",
          c16: "good_morning.mp3",
        },
      },
      2: {
        text: "Keller",
        active: false,
        music: { c0: "Seher vor Rauch.mp3", c6: "Seher nach Rauch.mp3" },
      },
    },
    dialogues: [
      {
        text: "Konrad Kommissar",
        audio: [
          "kommissar_1.0a.mp3",
          "anruf_konrad_1.mp3",
          "anruf_konrad_2.mp3",
        ],
        volume: 1,
        img: "konrad_px.png",
        setCondition: [
          [14, true],
          [16, false],
          [23, false],
        ],
        options: [
          {
            text: [
              "Ich hab nen tiefen Schlaf",
              "Ich kümmere mich darum (auflegen)",
            ],
            answer: ["kommissar_1.1a_1.mp3", "kommissar_1.1a_2.mp3"],
            count: 2,
            exit: true,
            condition: [[19, false]],
            setCondition: [
              [14, false],
              [19, true],
            ],
          },
          {
            text: "Ich kümmere mich darum (auflegen)",
            answer: ["konrad_mantel.mp3", "konrad_beeildich.mp3"],
            exit: true,
            condition: [[19, true]],
            setCondition: [[14, false]],
          },
          {
            text: "Ich habe eine Frage",
            answer: "kommissar_1.1b.mp3",
            nested: 1,
            condition: [[20, false]],
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "Wer bist du nochmals?",
                answer: "kommissar_1.2a.mp3",
              },
              {
                text: "Wie geht es Frau und Kindern?",
                answer: "kommissar_1.2b.mp3",
              },
              {
                text: "Was willst du schon wieder von mir?",
                answer: "kommissar_1.2c.mp3",
              },
              {
                text: "Das ist alles",
                answer: "kommissar_hm.mp3",
                return: true,
              },
            ],
          },
        },
      },
      /*
            {
        text: "Wahrsagerin",
        audio: ["seher_1.0a.mp3"],
        volume: 1,
        c_audio: { c5: ["seher_1.0b.mp3"], c6: ["seher_1.0c.mp3"] },
        img: "seher_px3.png",
        options: [
          {
            text: "1982",
            answer: "seher_1.1a.mp3",
            condition: [4, false],
            nested: 2,
          },
          {
            text: "1703",
            answer: "seher_1.1b.mp3",
            condition: [4, false],
            nested: 1,
          },
          {
            text: "560 v. Chr.",
            answer: "seher_1.1c.mp3",
            condition: [4, false],
            nested: 1,
          },
          {
            text: "2020",
            answer: "seher_1.1d.mp3",
            condition: [4, false],
            nested: 1,
          },
          {
            text: "Als Wahrsagerin solltest du das doch selber wissen?",
            answer: "seher_1.1e.mp3",
            condition: [4, false],
            count: 1,
          },
          {
            text: "Ah ja das reicht mir!",
            answer: "seher_1.1f.mp3",
            condition: [5, true],
            exit: true,
          },
          {
            text: "Ich verstehe das leider nicht!",
            answer: ["seher_1.1g.mp3", "seher_1.1h.mp3", "seher_1.1i.mp3"],
            condition: [5, true],
          },
          {
            text: "Ich möchte meine Zukunft wissen.",
            answer: "seher_1.1j.mp3",
            condition: [6, true],
            setCondition: [[7, true]],
            exit: true,
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "Ja",
                answer: "seher_1.2b.mp3",
                resolution: true,
              },
              {
                text: "Oh nein warte, ich habe mich geirrt.",
                answer: "seher_1.2a.mp3",
                resolution: true,
              },
            ],
          },
          2: {
            options: [
              {
                text: "Ja",
                answer: "seher_1.0b.mp3",
                setCondition: [
                  [4, true],
                  [5, true],
                ],
                resolution: true,
              },
              {
                text: "Oh nein warte, ich habe mich geirrt.",
                answer: "seher_1.2a.mp3",
                resolution: true,
              },
            ],
          },
        },
      },
       */
      {
        text: "Wahrsagerin",
        audio: ["seher_1.0a.mp3", ""],
        c_audio: { c20: ["seher_1.0c.mp3"], c5: ["seher_1.0c.mp3"] },
        volume: 1,
        img: "seher_px3.png",
        options: [
          {
            text: "1982",
            answer: "seher_1.1a.mp3",
            condition: [
              [4, false],
              [20, false],
            ],
            nested: 2,
          },
          {
            text: "1703",
            answer: "seher_1.1b.mp3",
            condition: [
              [4, false],
              [20, false],
            ],
            nested: 1,
          },
          {
            text: "560 v. Chr.",
            answer: "seher_1.1c.mp3",
            condition: [
              [4, false],
              [20, false],
            ],
            nested: 1,
          },
          {
            text: "2020",
            answer: "seher_1.1d.mp3",
            condition: [
              [4, false],
              [20, false],
            ],
            nested: 1,
          },
          {
            text: "Als Wahrsagerin solltest du das doch selber wissen?",
            answer: "seher_1.1e.mp3",
            condition: [
              [4, false],
              [20, false],
            ],
            count: 1,
          },
          {
            text: "Ich möchte meine Zukunft wissen.",
            answer: "seher_1.1j.mp3",
            condition: [[5, true]],
            exeption: [[20, true]],
            setCondition: [
              [7, true],
              [20, false],
            ],
            exit: true,
          },
          {
            text: "Werde ich meinen Mantel je wieder sehen?",
            answer: "seher_1.1j.mp3",
            condition: [[5, true]],
            exeption: [[20, true]],
            setCondition: [[7, true]],
            exit: true,
          },
          {
            text: "Wird mein Mantel je wieder im Winde wehen?",
            answer: "seher_1.1j.mp3",
            condition: [[5, true]],
            exeption: [[20, true]],
            setCondition: [[7, true]],
            exit: true,
          },
          {
            text: "Wartet eine Motorsäge auf mich?",
            answer: "seher_1.1j.mp3",
            condition: [[5, true]],
            exeption: [[20, true]],
            setCondition: [[7, true]],
            exit: true,
          },
          {
            text: "Kannst du mir Karten legen?",
            answer: "seher_1.1j.mp3",
            condition: [[5, true]],
            exeption: [[20, true]],
            setCondition: [[7, true]],
            exit: true,
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "Ja",
                answer: "seher_1.2b.mp3",
                resolution: true,
              },
              {
                text: "Oh nein warte, ich habe mich geirrt.",
                answer: "seher_1.2a.mp3",
                resolution: true,
              },
            ],
          },
          2: {
            options: [
              {
                text: "Ja",
                answer: "seher_1.0b.mp3",
                setCondition: [
                  [4, true],
                  [5, true],
                ],
                resolution: true,
              },
              {
                text: "Oh nein warte, ich habe mich geirrt.",
                answer: "seher_1.2a.mp3",
                resolution: true,
              },
            ],
          },
        },
      },
      {
        text: "Mutter",
        audio: ["mutter_.mp4"],
        idle: ["mutter_1.0.mp4"],
        setCondition: [[22, true]],
        options: [
          {
            text: "Wie gehts dir Mutter?",
            answer: [
              "mutter_1.1a.mp4",
              "mutter_1.1a_2.mp4",
              "mutter_1.1a_2.mp4",
              "mutter_1.1a_3.mp4",
            ],
          },
          {
            text: "Ich habe meinen Schlüssel verloren",
            answer: [
              "mutter_1.1b.mp4",
              "mutter_1.1b_2.mp4",
              "mutter_1.1b_2.mp4",
              "mutter_1.1b_3.mp4",
            ],
          },
          {
            text: "Sieh mal was ich erreicht habe",
            answer: [
              "mutter_1.1c.mp4",
              "mutter_1.1c_2.mp4",
              "mutter_1.1c_2.mp4",
              "mutter_1.1c_3.mp4",
            ],
          },
          {
            text: "Ich vermisse dich Mutter",
            answer: [
              "mutter_1.1d.mp4",
              "mutter_1.1d_2.mp4",
              "mutter_1.1d_2.mp4",
              "mutter_1.1d_3.mp4",
            ],
          },
          {
            text: "(weggehen)",
            answer: "_.mp3",
            setCondition: [[22, false]],
            exit: true,
          },
        ],
      },
      {
        text: "Ritterhelm",
        audio: ["r_ritter1.0.mp3", "r_monsueur.mp3"],
        //  img: "ludwig_px.png",
        setCondition: [[21, true]],
        options: [
          {
            text: "Hier ist König Artus mit seinen Rittern der Tafelrunde!",
            answer: "r_artus.mp3",
            count: 1,
            setCondition: [[1, true]],
          },
          {
            text: "Hier spricht die Polizei! Sie sind umzingelt!",
            answer: "r_hurensohn_ohne_hure.mp3",
            count: 1,
            setCondition: [[1, true]],
          },
          { text: "Wer bist du?", answer: "r_franzosen.mp3", count: 1 },
          //  { text: "(nichts sagen)", answer: "r_kleverererere.mp3", count: 1 },
          {
            text: "Ich brauche diese Taucherbrille",
            answer: "r_fuddiputze.mp3",
            condition: [[6, true]],
            nested: 1,
          },
          {
            text: "(Taucherbrille nehmen)",
            answer: "r_kleverererere.mp3",
            count: 1,
            condition: [[2, true]],
            setCondition: [
              [11, true],
              [21, false],
            ],
            exit: true,
          },
          {
            text: "(weggehen)",
            answer: "_.mp3",
            setCondition: [[21, false]],
            exit: true,
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: ["Kann ich...", "Ich möchte...", "Ach bitte..."],
                answer: [
                  "r_aufkeinenfall.mp3",
                  "r_verschwindet.mp3",
                  "r_frischbier.mp3",
                ],
                setCondition: [[2, true]],
                count: 3,
                resolution: true,
              },
              {
                text: "Die Brille steht dir sowieso nicht",
                answer: ["r_schweinepriester.mp3"],
                count: 1,
              },
              {
                text: "(Taucherbrille nehmen)",
                answer: "r_kleverererere.mp3",
                count: 1,
                condition: [[20, true]],
                setCondition: [
                  [11, true],
                  [21, false],
                ],
                exit: true,
              },
              {
                text: "Dann halt nicht",
                answer: ["r_pfff.mp3"],
                return: true,
              },
            ],
          },
        },
      },
      {
        text: "EFDAB-Hotline",
        setCondition: [[15, true]],
        audio: ["efdab_1.0a.mp3"],
        options: [
          {
            text: "Handeln Sie Motorsägen?",
            answer: "efdab_1.1a.mp3",
            nested: 1,
          },
          {
            text: "Was bieten Sie nochmals alles an?",
            answer: [
              "efdab_1.1b.mp3",
              "efdab_1.1c.mp3",
              "efdab_1.1d.mp3",
              "efdab_1.1e.mp3",
            ],
            count: 4,
            exit: true,
            setCondition: [[15, false]],
          },
          {
            text: ["Bitte, wirklich nur die Säge.", "BITTE NUR DIE SÄGE!"],
            answer: ["efdab_1.1g.mp3", "efdab_1.1h.mp3"],
            count: 2,
            setCondition: [[9, true]],
            condition: [[8, true]],
          },
          {
            text: "SÄÄÄGE!",
            answer: "efdab_1.1j.mp3",
            condition: [[9, true]],
            setCondition: [
              [9, false],
              [10, true],
            ],
            count: 1,
          },
          {
            text: "Ich nehme alles!",
            answer: "efdab_1.1i.mp3",
            condition: [[9, true]],
            exeption: [
              [20, true],
              [8, true],
            ],
            exit: true,
            setCondition: [[15, false]],
          },
          {
            text: "Arghhh .... schicken Sie mir alles!",
            answer: "efdab_1.1i.mp3",
            condition: [[10, true]],
            exit: true,
            setCondition: [[15, false]],
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "Ähm eigentlich möchte ich nur die Säge!",
                answer: ["efdab_1.1f.mp3"],
                setCondition: [[8, true]],
                resolution: true,
              },
            ],
          },
        },
      },
      /*     {
        text: "Typ in rotem Shirt",
        audio: ["ahoy.wav", "arr.mp3"],
        img: "ludwig_px.png",
        options: [
          { text: "Ä super Frag", answer: "he.wav" },
          { text: "Öppis unwichtigs", answer: "giveafuck.mp3", count: 1 },
          {
            text: "Töfflibueb?",
            answer: "give.wav",
            condition: [1, true],
            nested: 1,
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "Töfflibueb geben",
                answer: "failed.wav",
                setCondition: [
                  [1, false],
                  [5, true],
                ],
                resolution: true,
                return: true,
              },
              {
                text: "Töfflibueb behalten",
                answer: "moron.mp3",
                return: true,
              },
            ],
          },
        },
      },
      {
        text: "Mika šljivovica",
        audio: ["slivo.wav", "dah.wav"],
        img: "rr_px.png",
        options: [
          {
            text: "Nimsch Drogä oder was?",
            answer: "weird.wav",
            nested: 1,
            resolvable: true,
          },
          {
            text: "nid nested?",
            answer: "weird.wav",
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "I wott o",
                answer: "lsd.wav",
                setCondition: [[2, true]],
                resolution: true,
              },
              {
                text: "Das wotti nid",
                answer: ["doitman.wav", "youshould.wav", "uff.wav"],
                count: 3,
                resolution: true,
                setCondition: [[3, true]],
              },
              {
                text: "Vielleicht später nimo o",
                answer: "burp.wav",
                return: true,
              },
              { text: "Hä?", answer: "dah.wav" },
            ],
          },
        },
      },
      {
        text: "Ä logeschi Sach füre @mo",
        audio: ["ahoy.wav", "arr.mp3"],
        options: [
          { text: "Ä normali Frag", answer: "he.wav" },
          {
            text: "Ä angeri Frag wome nume einisch darf steue",
            answer: "giveafuck.mp3",
            count: 1,
          },
          {
            text: "Eini wome drü mau cha steue",
            answer: ["giveafuck.mp3", "tuut.wav", "arr.mp3"],
            count: 3,
          },
          {
            text: "Ä Frag wome ersch gseht wennme dr Töfflibueb het",
            answer: "give.wav",
            condition: [1, true],
          },
          {
            text: "Ä Frag wom nöii Frage ufwirft",
            answer: "give.wav",
            nested: 1,
          },
        ],
        nested: {
          1: {
            options: [
              {
                text: "D Lösig",
                answer: "tuut.wav",
                resolution: true,
              },
              {
                text: "D Lösig, aber ersch nach drü mau",
                answer: ["doitman.wav", "youshould.wav", "uff.wav"],
                count: 3,
                resolution: true,
                setCondition: [[3, true]],
              },
              { text: "Nomau ä gwöhnelchi Frag", answer: "dah.wav" },
              {
                text: "Zrüg ohni lösig",
                answer: "burp.wav",
                return: true,
              },
            ],
          },
        },
      },
      {
        text: "Clown",
        audio: "clownworld.mp3",
        condition: [2, true],
        options: [
          { text: "Du bischmer doch ä Clown!", answer: "crazyclown.wav" },
          { text: "Iz hör doch uf!", answer: "music.wav" },
          { text: "Äuä scho!", answer: "tuut.wav" },
        ],
      },*/
    ],
    conditions: {
      1: { text: "_d_taucherbrille_1", check: false },
      2: { text: "_d_taucherbrille_2", check: false },
      3: { text: "_i_taucherbrille", check: false },
      4: { text: "_d_geburtsdatum", check: false },
      5: { text: "_d_tipp", check: false },
      6: { text: "Rauch", check: false },
      7: { text: "Karten erhalten", check: false },
      8: { text: "_d_faltwerkbank", check: false },
      9: { text: "_d_faltstellwände", check: false },
      10: { text: "_d_raptorenfutter", check: false },
      11: { text: "_i_brille", check: false },
      12: { text: "_i_schlauch", check: false },
      13: { text: "Schrank geöffnet", check: false },
      14: { text: "_m_konrad", check: false },
      15: { text: "_m_off", check: false },
      16: { text: "_m_telefon", check: true },
      17: { text: "Mantel gefunden", check: false },
      18: { text: "Pfeil genommen", check: false },
      19: { text: "_d_konrad", check: false },
      20: { text: "Keine Zeit", check: false },
      21: { text: "_m_ritter", check: false },
      22: { text: "_m_mutter", check: false },
      23: { text: "Konrad ruft an", check: false },
      24: { text: "Mantel angezogen", check: false },
      25: { text: "_i_schlüssel", check: false },
      26: {
        text: "_s_nicht angezogen",
        check: true,
        count: 3,
        target: 0,
        action: false,
      },
      27: { text: "_k_schlüssel verloren", check: false },
      28: { text: "_i_mütze", check: false, sub: 26 },
      29: { text: "_i_hosenträger", check: false, sub: 26 },
      30: { text: "_i_bademantel", check: false, sub: 26 },
      /*  4: { text: "Ludwig hat einen sitzen", check: false },
      5: { text: "Käse im Mantel", check: false },
      6: { text: "Irgendwas", check: false },
      7: { text: "Konami Code eingegeben", check: false },*/
    },
    events: {
      1: { text: "Game Over", video: "game_over.mp4", wait: true },
    },
    inventory: [
      {
        text: "Flachmann",
        img: "flask.png",
        inMantu: true,
      },
      {
        text: "Schlüssel",
        img: "schluessel_px.png",
        setCondition: {
          out: [
            [5, false],
            [13, true],
          ],
        },
        onCondition: {
          out: [[17, true]],
        },
        isCondition: 25,
        inMantu: false,
      },
      {
        text: "Beil",
        img: "axt_px.png",
        onCondition: {
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Messer",
        img: "messer_px.png",
        onCondition: {
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Katana",
        img: "katana_px.png",
        onCondition: {
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Kettensäge",
        img: "dolmar_px.png",
        onCondition: {
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Taucherbrille",
        img: "taucherbrille_px.png",
        inMantu: false,
        isCondition: 11,
        onCondition: {
          out: [[17, true]],
        },
      },
      {
        text: "Schlauch",
        img: "schlauch_px.png",
        inMantu: false,
        isCondition: 12,
        onCondition: {
          out: [[17, true]],
        },
      },
      {
        text: "Gasmaske",
        img: "schlauch_comb_px.png",
        inMantu: false,
        setCondition: {
          in: [
            [12, false],
            [11, false],
          ],
        },
        onCondition: {
          out: [[17, true]],
        },
      },
      {
        text: "Arschkarte",
        img: "arschkarte_px.png",
        onCondition: {
          in: [[7, true]],
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Speisekarte",
        img: "speisekarte_px.png",
        onCondition: {
          in: [[7, true]],
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Landkarte",
        img: "landkarte_px.png",
        onCondition: {
          in: [[7, true]],
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "EFDAB-Karte",
        img: "efdab_px.png",
        onCondition: {
          in: [[7, true]],
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Pfeil",
        img: "arrow.gif",
        onCondition: {
          in: [[18, true]],
          out: [[17, true]],
        },
        inMantu: false,
      },
      {
        text: "Hockeyschläger",
        img: "schlaeger_px.png",
        inMantu: false,
        onCondition: {
          in: [[24, true]],
        },
      },
      {
        text: "Foto",
        img: "opfer_tatort_px.png",
        inMantu: false,
        onCondition: {
          in: [[24, true]],
        },
      },
      {
        text: "Türklinke",
        img: "tuerklinke_px.png",
        inMantu: false,
        onCondition: {
          in: [[24, true]],
        },
      },
      {
        text: "Polaroid",
        img: "polaroid_px.png",
        inMantu: false,
        onCondition: {
          in: [[24, true]],
        },
      },
      {
        text: "Manabar",
        img: "manabar_px.png",
        inMantu: false,
        onCondition: {
          in: [[24, true]],
        },
      },
    ],
    tips: [
      { current: 0, custom: 0 },
      "Ludwig schaut manchmal instinktiv die Gegenstände an, die ihm weiterhelfen. Wenn du nicht weiter weisst, achte darauf, wo Ludwig hinschaut. ",
      "Ludwig hat Kopfschmerzen – manchmal reagiert er nicht sofort.",
      "Hier könnte Ihre Werbung stehen",
      "Ludwig ist schnell verwirrt, wenn zu viele Stimmen in seinem Kopf gleichzeitig sprechen",
      "Hilf Ludwig dabei, seinen nächsten Fall zu lösen: ludwig-arts.ch/start",
      "Ludwig versteht dich besser, wenn du deutlich und direkt ins Mikrofon sprichst",
      "Du kannst jederzeit nach Hinweisen fragen",
    ],
    speaker: [
      {
        text: "Mütze",
        chapter: 1,
        actions: {
          n: {
            c0: {
              text: "Ludwig nahm die Mütze und setzte sie sich auf. Er fühlte sich gleich viel vollständiger.",
              setCondition: [[28, true]],
            },
          },
          b: {
            c0: {
              text: "Ludwig setzte sich die Mütze auf und fühlte sich gleich viel vollständiger.",
              setCondition: [[28, true]],
            },
          },
          r: {
            c0: "Oh du Mütze – gerettet aus einer Pfütze – Ich mag dich gern – du kommst aus Bern",
          },
          d: { c0: "Sanft. Fast so sanft wie Kaschmir. " },
          s: {
            c0: "Eine echt tolle Mütze. Auf Ludwigs Kopf würde sie noch besser aussehen.",
          },
          z: {
            c0: "Ludwig zieht einen losen Faden aus der Mütze. Wie oft er das wohl noch machen durfte, bevor seine bevorzugte Kopfbedeckung jegliche Substanz verliert?",
          },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Bademantel",
        chapter: 1,
        actions: {
          n: { c0: { text: "...", setCondition: [[30, true]] } },
          b: { c0: { text: "...", setCondition: [[30, true]] } },
          r: { c0: "..." },
          d: { c0: "..." },
          s: { c0: "..." },
          z: { c0: "..." },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Hosenträger",
        chapter: 1,
        actions: {
          n: { c0: { text: "...", setCondition: [[29, true]] } },
          b: { c0: { text: "...", setCondition: [[29, true]] } },
          r: { c0: "..." },
          d: { c0: "..." },
          s: { c0: "..." },
          z: {
            c0: {
              text: "Das könnte ins Auge gehen, dachte sich Ludwig und zog dennoch an den Hosenträgern. Das war ein Fehler...",
              startEvent: 1,
            },
            c26: "Ludwig zieht die Hosenträger... an",
          },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Schlauch",
        chapter: 1,
        actions: {
          n: {
            c0: {
              text: "Nehmen wenn bedingung nicht gesetzt ist",
              setCondition: [[12, true]],
            },
            c6: "Ludwig steckt den Schlauch ein. ",
          },
          b: { c0: "Ludwig pustet in den Schlauch. Dichter, wie Goethe." },
          r: { c0: "Wäre ich ein Schlauch, hätte ich keine Sorgen." },
          d: {
            c0: "Nach einem Druck-Test stellt sich heraus, der Schlauch ist hochwertig.",
          },
          s: { c0: "Ein Bilderbuch-Schlauch. Der könnte nützlich sein. " },
          z: { c0: "Robuster Schlauch. Der hält was aus." },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Schlüssel",
        chapter: 1,
        isItem: 25,
        actions: {
          n: {
            c0: {
              text: "Der könnte nützlich sein. Ludwig steckte den Schlüssel ein.",
              setCondition: [
                [25, true],
                [27, false],
              ],
            },
          },
          b: {
            c0: "Ludwig drehte den Schlüssel um – nichts öffnet sich. Da scheint etwas zu fehlen. ",
          },
          r: {
            c0: "«Du bist genau wonach ich suche», grummelte Ludwig in Lampe.",
          },
          d: { c0: "Da gibt es nichts zu drücken." },
          s: { c0: "Ein Messingfarbener Schlüssel aus einer anderen Epoche. " },
          z: { c0: "Ludwig zückte den Schlüssel und steckte ihn ein." },
          c: {
            default: { c0: "benutze mit niete" },
            Schrank_links: {
              c0: {
                text: "blabla",
                setCondition: [
                  [13, true],
                  [25, false],
                ],
              },
            },
          },
        },
      },
      {
        text: "Schrank_links",
        chapter: 1,
        actions: {
          n: {
            c0: "...",
            c26: [
              "das passiert sofort",
              {
                text: "das passiert erst später und setzt eine Bedingung",
                setCondition: [[27, true]],
              },
            ],
          },
          b: {
            c0: { text: "benutzen wenn angezogen", setCondition: [[27, true]] },
            c26: {
              text: "benutzen wenn nicht angezogen",
              setCondition: [[27, true]],
            },
          },
          r: { c0: "rede" },
          d: { c0: "drücke" },
          s: { c0: "schau" },
          z: {
            c0: { text: "ziehen wenn angezogen", setCondition: [[27, true]] },
            c26: {
              text: "ziehen wenn nicht angezogen",
              setCondition: [[27, true]],
            },
          },
          c: {
            default: { c0: "..." },
            Schlüssel: {
              c0: "Ludwig konnte die Schranktür nicht zum Schlüssel in seiner Tasche ziehen – sie war verschlossen.",
            },
          },
        },
      },
      {
        text: "Schrank_rechts",
        chapter: 1,
        actions: {
          n: { c0: "Nehmen wenn bedingung nicht gesetzt ist" },
          b: { c0: "benutze" },
          r: { c0: "rede" },
          d: { c0: "drücke" },
          s: { c0: "schau" },
          z: { c0: "ziehe" },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Bild der Mutter",
        chapter: 1,
        actions: {
          n: {
            c0: "Ludwig wollte das mit Müh und Not hingehängte Bild, mit den Nägeln die er endlich ergattert hatte, nicht von der Wand nehmen.",
          },
          b: { c0: "Ludwig zuckte nur mit den Schultern. Er wollte nicht. " },
          r: {
            c0: "Ludwig sah keinen Grund mit seiner Mutter zu reden",
            c26: "Ludwig sah keinen Grund mit seiner Mutter zu reden",
            c27: {
              text: "Ludwig wandte sich an das Bild seiner Mutter und konzentrierte sich",
              action: ">talk:Mutter",
            },
          },
          d: {
            c0: "Die Zeiten in denen er seine Mutter gedrückt hatte, sind schon lange vorbei. ",
          },
          s: {
            c0: "Als er seine Mutter so ansah, lief ihm ein kalter Schauer über den Rücken.",
          },
          z: {
            c0: "Ludwig wollte das mit Müh und Not hingehängte Bild, mit den Nägeln die er endlich ergattert hatte, nicht von der Wand nehmen.",
          },
          c: {
            default: { c0: "..." },
          },
        },
      },
      {
        text: "Kiste",
        chapter: 2,
        actions: {
          n: { c0: "Nehmen wenn bedingung nicht gesetzt ist" },
          b: { c0: "benutze" },
          r: { c0: "rede" },
          d: { c0: "drücke" },
          s: { c0: "schau" },
          z: { c0: "ziehe" },
          c: {
            default: { c0: "..." },
          },
        },
      },
    ],
    dialogue: null,
    subdialogue: null,
    selected: null,
    talkingTo: null,
    busy: false,
    talking: false,
    video: false,
    event: false,
    idle: "",
    loading: true,
    installed: true,
    timer: 0,
    music: null,
    arrow: false,
    arrowDefault: false,
    muted: false,
    chapter: 1,
    waitingForMo: false,
    skipContinue: false,
  },
  mutations: {
    currentDialogue(state, d) {
      // mutate state
      state.dialogue = d;
    },
    currentSubdialogue(state, d) {
      // mutate state
      state.subdialogue = d;
    },
    select(state, id) {
      // mutate state
      state.selected = id;
    },
    talk(state, name) {
      // mutate state
      state.talkingTo = name;
    },
    condition(state, val) {
      // mutate state
      state.conditions[val.id].check = val.bool;
      if ("add" in state.conditions[val.id]) {
        state.conditions[state.conditions[val.id].add].count++;
        if (
          state.conditions[state.conditions[val.id].add].count ==
          state.conditions[state.conditions[val.id].add].target
        ) {
          state.conditions[state.conditions[val.id].add].check =
            state.conditions[state.conditions[val.id].add].action;
        }
      }
      if ("sub" in state.conditions[val.id]) {
        state.conditions[state.conditions[val.id].sub].count--;
        if (
          state.conditions[state.conditions[val.id].sub].count ==
          state.conditions[state.conditions[val.id].sub].target
        ) {
          state.conditions[state.conditions[val.id].sub].check =
            state.conditions[state.conditions[val.id].sub].action;
        }
      }
      let items = state.inventory.filter((i) => i.isCondition == val.id);
      if (items.length > 0) {
        for (let item of items) {
          item.inMantu = val.bool;
        }
      }
      items = state.inventory.filter((i) => "onCondition" in i);
      for (let item of items) {
        if ("in" in item.onCondition) {
          if (
            item.onCondition.in.filter(
              (i) => i[0] == val.id && i[1] == val.bool
            ).length > 0
          )
            item.inMantu = true;
        }
        if ("out" in item.onCondition) {
          if (
            item.onCondition.out.filter(
              (i) => i[0] == val.id && i[1] == val.bool
            ).length > 0
          )
            item.inMantu = false;
        }
      }
    },
    wait(state) {
      state.busy = true;
    },
    continue(state) {
      if (state.waitingForMo == true) {
        state.waitingForMo = false;
      } else {
        state.busy = false;
      }
    },
    waitForMo(state) {
      state.waitingForMo = true;
      //  state.busy = true;
    },
    talking: (state, bool) => {
      state.talking = bool; //.filter(todo => todo.done)
    },
    load(state) {
      state.loading = true;
    },
    loaded(state) {
      state.loading = false;
      state.arrow = state.arrowDefault;
    },
    install(state) {
      state.installed = true;
    },
    startInstall(state) {
      state.installed = false;
    },
    setTimer: (state, min) => {
      state.timer = min; //.filter(todo => todo.done)
    },
    playVideo: (state, url) => {
      state.video = url; //.filter(todo => todo.done)
    },
    playEvent: (state, url) => {
      state.event = url; //.filter(todo => todo.done)
    },
    stopVideo: (state) => {
      state.video = false; //.filter(todo => todo.done)
      state.event = false;
    },
    setIdle: (state, idle) => {
      state.idle = idle;
    },
    toggleArrow: (state) => {
      state.arrow = !state.arrow;
    },
    toggleArrowDefault: (state) => {
      state.arrowDefault = !state.arrowDefault;
    },
    setChapter: (state, id) => {
      for (let chapter in state.chapters) {
        state.chapters[chapter].active = false;
      }
      state.chapters[id].active = true;
      state.chapter = id;
    },
    mute: (state) => {
      state.muted = true;
    },
    toggleMute: (state) => {
      state.muted = !state.muted;
    },
    skip: (state, bool) => {
      state.skipContinue = bool;
    },
  },
  actions: {
    setMusic({ state }, music) {
      if (state.music != null) {
        state.music.pause();
        state.music.currentTime = 0;
      }
      state.music = new Audio(require("../assets/audio/" + music));
      state.music.loop = true;
      state.music.volume = 0.2;
      state.music.play();
    },
  },
  modules: {},
  getters: {
    speaker: (state) => {
      return state.speaker
        .filter((item) => {
          if ("isItem" in item) {
            if (state.conditions[item.isItem].check) {
              return true;
            }
          }
          return item.chapter == state.chapter;
        })
        .sort(function (a, b) {
          let textA = a.text.toUpperCase();
          let textB = b.text.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      //  return state.speaker.filter((item) => item.chapter == state.chapter);
    },
    music: (state) => {
      return state.music; //.filter(todo => todo.done)
    },
    dialogues: (state) => {
      return state.dialogues; //.filter(todo => todo.done)
    },
    subdialogue: (state) => {
      return state.subdialogue;
    },
    conditions: (state) => {
      return state.conditions; //.filter(todo => todo.done)
    },
    currentDialogue: (state) => {
      return state.dialogue; //.filter(todo => todo.done)
    },
    anydialogue: (state) => {
      if (state.subdialogue != null) {
        return state.subdialogue;
      }
      return state.dialogue;
    },
    selected: (state) => {
      return state.selected; //.filter(todo => todo.done)
    },
    talkingTo: (state) => {
      return state.talkingTo; //.filter(todo => todo.done)
    },
    items: (state) => {
      return state.inventory; //.filter(todo => todo.done)
    },
    inventory: (state) => {
      return state.inventory.filter((i) => i.inMantu); //.filter(todo => todo.done)
    },
    isBusy: (state) => {
      return state.busy; //.filter(todo => todo.done)
    },
    isTalking: (state) => {
      return state.talking; //.filter(todo => todo.done)
    },
    loading: (state) => {
      return state.loading; //.filter(todo => todo.done)
    },
    tips: (state) => {
      return state.tips; //.filter(todo => todo.done)
    },
    installed: (state) => {
      return state.installed;
    },
    time: (state) => {
      return state.timer;
    },
    video: (state) => {
      return state.video;
    },
    event: (state) => {
      return state.event;
    },
    idle: (state) => {
      return state.idle;
    },
    chapters: (state) => {
      return state.chapters;
    },
    arrow_r: (state) => {
      if (
        !state.isBusy &&
        !state.dialogue &&
        !state.loading &&
        state.installed &&
        !state.talkingTo
      ) {
        // condition check sauber lösen
        return state.arrow ? !state.conditions[18].check : false;
      }
      return false;
    },
    arrow_default: (state) => {
      return state.arrowDefault;
    },
    currentMusic: (state) => {
      if (state.muted) {
        return "_.mp3";
      }
      if (state.loading) {
        if (state.installed) {
          return "loading.mp3";
        }
        return "_.mp3";
      }
      //TODO: simplify
      for (let id in state.chapters) {
        if (state.chapters[id].active == true) {
          for (let c in state.chapters[id].music) {
            let condition = c.replace("c", "");
            if (condition > 0) {
              if (state.conditions[condition].check == true) {
                return state.chapters[id].music["c" + condition];
              }
            }
          }
          return state.chapters[id].music.c0;
        }
      }
    },
    muted: (state) => {
      return state.muted;
    },
    waitingForMo: (state) => {
      return state.waitingForMo;
    },
    events: (state) => {
      return state.events;
    },
    skip: (state) => {
      return state.skipContinue;
    },
  },
});
