<template>
  <div class="cover">
    <img class="waiting" src="@/assets/loading.gif" />
    <h1>{{ tip }}</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Waiting",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      timer: undefined,
    };
  },
  computed: {
    ...mapGetters(["tips"]),
    tip() {
      let current;
      if (this.tips[0].custom != 0) {
        return this.tips[0].custom;
      }
      if (this.tips[0].current == 0) {
        current = this.tips.length - 1;
      } else {
        current = this.tips[0].current;
      }
      return this.tips[current];
    },
  },
  mounted() {
    this.setTimer();
    //  document.addEventListener("keydown", this.nextItem);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    setTimer() {
      const self = this;
      this.timer = setTimeout(self.changeTip, 8000);
    },
    changeTip() {
      this.tips[0].custom = 0;
      let current = this.tips[0].current;
      current--;
      if (current < 1) {
        this.tips[0].current = this.tips.length - 1;
      } else {
        this.tips[0].current = current;
      }
      this.setTimer();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";

.cover {
  background: #e5007e;
}

h1 {
  flex-direction: column;
  font-size: $tip;
  font-family: lores-15-narrow, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  max-width: 80%;
  text-align: center;
}
</style>
