<template>
  <div>
    <ul v-if="_conditions"></ul>
    <li v-for="(condition, idx) in _conditions" :key="idx">
      <span
        class="conditions"
        @click="setCondition(condition, idx)"
        v-bind:class="{ active: condition.check }"
      >
        {{ condition.text }}
      </span>
    </li>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Public",
  props: {
    _conditions: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["items", "conditions"]),
  },
  methods: {
    setCondition(condition, id) {
      //TODO Solve
      console.log(id);
      let idc = Object.values(this.conditions).findIndex(
        (c) => c.text == condition.text
      );
      //console.log(idc);
      this.$store.commit("condition", { id: idc + 1, bool: !condition.check });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_shared_private.scss";
@import "../scss/_variables.scss";

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: $margin-default $margin-default;
}
</style>
