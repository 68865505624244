<template>
  <div id="dialogues">
    <div class="options" v-if="!anydialogue">
      <div
        class="selectOption"
        v-for="(d, idx) in dialogues.filter(isShown)"
        :key="idx"
      >
        <span class="option" @click="setDialogue(d)">{{
          getText(d.text)
        }}</span>
      </div>
    </div>
    <ul v-if="anydialogue">
      <li
        v-for="(option, idx) in anydialogue.options.filter(isShown)"
        :key="idx"
      >
        <span class="option" @click="ChooseOption(option, idx)">{{
          getText(option.text)
        }}</span>
      </li>
      <li>
        <span class="option" @click="leave()"> Verlassen </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { isShown } from "../mixins/isShown.js";
import { setCondition } from "../mixins/setCondition.js";
import { getText } from "../mixins/getText.js";

import { mapGetters } from "vuex";

export default {
  name: "Dialogue",
  mixins: [isShown, setCondition, getText],
  components: {},
  props: {},
  data() {
    return {
      audio: undefined,
      currentOption: [],
      cDialogue: "",
    };
  },
  computed: {
    ...mapGetters([
      "dialogues",
      "subdialogue",
      "conditions",
      "currentDialogue",
      "anydialogue",
      "talkingTo",
      "selected",
      "items",
      "music",
      // ...
    ]),
  },
  mounted() {},
  methods: {
    setDialogue(d) {
      const self = this;
      if (this.selected == null) {
        if ("setCondition" in d) {
          this.setCondition(d.setCondition);
        }
        this.$store.commit("currentDialogue", null);
        this.$store.commit("wait");
        let file = null;
        if ("c_audio" in d) {
          for (let c in d.c_audio) {
            let condition = c.replace("c", "");
            if (this.conditions[condition].check == true) {
              if (d.c_audio[c].constructor === Array) {
                file =
                  d.c_audio[c].length > 1
                    ? d.c_audio[c].shift()
                    : d.c_audio[c][0];
              } else {
                file = d.c_audio[c];
              }
            }
          }
        }
        if ("audio" in d) {
          this.$store.commit("talk", d.text);

          if (d.audio.constructor === Array && file == null) {
            file = d.audio.length > 1 ? d.audio.shift() : d.audio[0];
          } else if (file == null) {
            file = d.audio;
          }
          if (file.includes(".mp4")) {
            this.$store.commit("playVideo", file);
            if ("idle" in d) {
              this.$store.commit("setIdle", d.idle);
            } else {
              this.$store.commit("setIdle", file);
            }
          }
          this.audio = new Audio(require("../assets/audio/" + file));
          this.audio.play();
          //set volume
          this.audio.volume = "volume" in d ? d.volume : 1;

          setTimeout(function () {
            self.music.volume = 0.06;
          }, 10);

          this.cDialogue = d;
          this.audio.addEventListener("ended", function () {
            self.audio.currentTime = 0;
            self.$store.commit("currentDialogue", d);
            self.$store.commit("continue");
          });
        } else {
          self.$store.commit("currentDialogue", d);
        }
        d.count++;
      }
    },
    ChooseOption(option, id) {
      if (this.selected == null) {
        const self = this;
        this.$store.commit("select", id);
        if ("answer" in option) {
          let file;
          if (option.answer.constructor === Array) {
            file =
              option.answer.length > 1
                ? option.answer.shift()
                : option.answer[0];
          } else {
            file = option.answer;
          }
          this.$store.commit("wait");
          if (file.includes(".mp4")) {
            this.$store.commit("playVideo", file);
          }

          this.audio = new Audio(require("../assets/audio/" + file));
          this.audio.play();
          this.currentOption = [option, id];
          this.audio.addEventListener("ended", function () {
            self.audio.currentTime = 0;
            self.continue(option, id);
            self.$store.commit("continue");
          });
        } else {
          this.currentOption = [option, id];
          this.$store.commit("talking", true);
          this.$store.commit("wait");
        }
      }
    },
    continue(option, id) {
      if (option.text.constructor === Array) {
        option.text.length > 1 ? option.text.shift() : null;
      }
      this.currentOption = [];
      if ("count" in option) {
        option.count--;
      }
      if ("exit" in option) {
        if ("count" in option) {
          if (option.count < 1) {
            this.leave();
          }
        } else {
          this.leave();
        }
      }
      if ("nested" in option) {
        this.$store.commit(
          "currentSubdialogue",
          this.currentDialogue.nested[option.nested]
        );
        this.option = id;
      }
      if ("resolution" in option) {
        if (option.resolution == true) {
          if ("count" in option) {
            if (option.count == 0) {
              this.resolve();
            }
          } else {
            this.resolve();
          }
        }
      }
      if ("setCondition" in option) {
        if ("count" in option) {
          if (option.count == 0) {
            this.setCondition(option.setCondition);
          }
        } else {
          this.setCondition(option.setCondition);
        }
      }
      if ("return" in option) {
        if (option.return) {
          this.$store.commit("currentSubdialogue", null);
        }
      }
      this.$store.commit("select", null);
    },

    stopAudio() {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.currentOption.length > 0
        ? this.continue(this.currentOption[0], this.currentOption[1])
        : this.$store.commit("currentDialogue", this.cDialogue);
      this.$store.commit("continue");
    },
    endAnswer() {
      this.continue(this.currentOption[0], this.currentOption[1]);
      this.$store.commit("continue");
      this.$store.commit("talking", false);
    },
    resolve() {
      this.currentDialogue.options.filter(this.isShown)[this.option].count = 0;
      this.$store.commit("currentSubdialogue", null);
    },
    leave() {
      this.$store.commit("currentDialogue", null);
      this.$store.commit("currentSubdialogue", null);
      this.$store.commit("talk", null);
      this.$store.commit("stopVideo");
    },
    addOption(o) {
      //this.$store.commit("addOption", option);
      let option;
      if (o.includes(">add resolve:")) {
        option = option = {
          text: o.replace(">add resolve:", "").trim(),
          resolution: true,
        };
        this.subdialogue.options.push(option);
      } else if (o.includes(">add return:")) {
        option = option = {
          text: o.replace(">add return:", "").trim(),
          return: true,
        };
        this.subdialogue.options.push(option);
      } else {
        option = { text: o.replace(">add:", "").trim(), count: 1 };
        this.anydialogue.options.push(option);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 10px 10px;
  cursor: pointer;
}
li:hover {
  //  font-weight: bold;
}
div.options {
  margin-top: 10px;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
}
div.selectOption {
  margin: 5px 10px;
}

span.option {
  cursor: pointer;
  font-weight: bold;
  color: white;
  min-width: 100px;
  display: inline-block;
  padding: 0px 5px;
}
span.option:hover {
  background: white;
  color: black;
}
a {
  color: #42b983;
}

#private {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  background: rgb(53, 53, 53);
}
#console {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.console {
  font-size: 20px;
  font-family: "Courier New", Courier, monospace;
  color: white;
}

input.console {
  display: block;
  width: 400px;
  height: 20px;
  font-size: 20px;
  padding: 8px;
  font-family: "Courier New", Courier, monospace;
  margin: 20px 0;
  background: black;
  color: white;
}
input:focus {
  outline: none;
}
input {
  border: 1px solid rgb(77, 77, 77);
  border-radius: 3px;
}
.row {
  display: flex;
  flex-direction: row;
}
#dialogues {
  text-align: left;
  width: 50%;
  color: white;
}
#conditions {
  width: 50%;
}
#inventory {
  width: 100%;
}
#public {
  width: 100%;
  height: 50%;
  background: transparent;
  cursor: none;
}
.public {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.name {
  position: absolute;
  display: list-item;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.active {
  color: #353535;
  background: white;
}
</style>
