<template>
  <div id="private">
    <Tabs />
    <div id="console">
      <div class="console" @keydown="nextItem">
        <input
          class="console"
          type="text"
          v-model="command"
          @input="checkCommand"
          @focus="showPropositions"
          @focusout="hidePropositions"
        />
        <ul v-if="showProp">
          <li
            v-for="(proposition, idx) in propositions"
            :key="idx"
            @click="setCommand(proposition)"
            :class="{ active: currentItem === idx }"
            :ref="{ active: currentItem === idx }"
          >
            {{ writeProposition(proposition) }}
          </li>
        </ul>
      </div>
    </div>
    <div id="speaker">
      <Speaker v-bind:interaction="interaction" ref="_speaker" />
    </div>
    <div class="row">
      <Dialogue ref="_dialogue" />
      <div id="conditions">
        <Conditions
          v-bind:_conditions="
            Object.values(conditions)
              .filter(filterImportantConditions)
              .sort(function (a, b) {
                let textA = a.text.toUpperCase();
                let textB = b.text.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          "
          ref="_conditions"
        />
      </div>
    </div>
    <div id="inventory">
      <Inventory ref="_inventory" />
    </div>
    <div class="conditions">
      <Conditions
        v-bind:_conditions="
          Object.values(conditions)
            .filter(filterConditions)
            .sort(function (a, b) {
              let textA = a.text.toUpperCase();
              let textB = b.text.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
        "
        ref="_conditions"
      />
    </div>
  </div>
</template>

<script>
import { isShown } from "../mixins/isShown.js";
import { setCondition } from "../mixins/setCondition.js";
import { getText } from "../mixins/getText.js";

import { mapGetters } from "vuex";

import Speaker from "./speaker.vue";
import Dialogue from "./dialogue.vue";
import Conditions from "./conditions.vue";
import Inventory from "./inventory.vue";
import Tabs from "./tabs.vue";

export default {
  name: "Private",
  mixins: [isShown, setCondition, getText],
  components: {
    Speaker,
    Conditions,
    Inventory,
    Dialogue,
    Tabs,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      command: "",
      re: /(>[a-z]*:|>exit|>continue|>skip)(.*)/i,
      propositions_default: [
        { text: ">take:" },
        { text: ">remove:" },
        { text: ">talk:" },
        { text: ">set:" },
        { text: ">unset:" },
        { text: ">upgrade:" },
        { text: ">wait:" },
      ],
      interactions: [
        { text: "nimm", action: "n" },
        { text: "benutze", action: "b" },
        { text: "rede", action: "r" },
        { text: "drücke", action: "d" },
        { text: "schau", action: "s" },
        { text: "ziehe", action: "z" },
        { text: "benutze mit", action: "c" },
      ],
      interaction: {},
      propositions: [{ text: ">continue" }],
      currentItem: 0,
      com: "",
      showProp: false,
    };
  },
  computed: {
    ...mapGetters([
      "dialogues",
      "subdialogue",
      "conditions",
      "currentDialogue",
      "anydialogue",
      "talkingTo",
      "selected",
      "items",
      "isBusy",
      "isTalking",
      "loading",
      "tips",
      "chapters",
      "music",
      "currentMusic",
      "arrow_r",
      "installed",
      "speaker",
      "waitingForMo",
      "skip",
      // ...
    ]),
  },
  mounted() {
    this.$store.dispatch("setMusic", "loading.mp3");
  },
  watch: {
    isBusy() {
      if (this.isBusy == false) {
        this.music.volume = 0.2;
        //custom
        this.$store.commit("condition", {
          id: 15,
          bool: false,
        });
        this.continue();
      } else {
        this.music.volume = 0.06;
      }
    },
    currentMusic() {
      this.$store.dispatch("setMusic", this.currentMusic);
    },
  },
  methods: {
    writeProposition(p) {
      if (typeof p === "object") {
        return p.text;
      }
      return p;
    },
    changeChapter() {},
    filterConditions(c) {
      if (c.text.includes("_m_")) return true;
      if (c.text.includes("_d_")) return true;
      if (c.text.includes("_i_")) return true;
      if (c.text.includes("_k_")) return true;
      if (c.text.includes("_s_")) return true;
      return false;
    },
    filterImportantConditions(c) {
      if (c.text.includes("_m_")) return false;
      if (c.text.includes("_d_")) return false;
      if (c.text.includes("_i_")) return false;
      if (c.text.includes("_k_")) return false;
      if (c.text.includes("_s_")) return false;
      return true;
    },
    sendCommand() {
      const self = this;
      const commands = {
        say(c) {
          self.say(c);
        },
        talk(c) {
          self.talk(c);
        },
        take(c) {
          self.take(c);
        },
        remove(c) {
          self.remove(c);
        },
        set(c) {
          self.set(c);
        },
        unset(c) {
          self.unset(c);
        },
        upgrade(c) {
          self.upgrade(c);
        },
        add(c) {
          self.add(c);
        },
        exit() {
          self.$refs._dialogue.leave();
          this.command = "";
          this.com = "";
          self.continue();
        },
        skip() {
          self.$refs._dialogue.stopAudio();
        },
        wait(c) {
          self.wait(c);
        },
        continue() {
          if (self.waitingForMo) {
            self.$refs._speaker.continue();
          } else if (!self.loading) {
            self.$refs._dialogue.endAnswer();
            self.continue();
          } else {
            self.continue();
          }
        },
      };
      this.currentItem = 0;
      if (this.command.match(this.re)) {
        let com = this.command.match(this.re);
        //  console.log('this.command', com)
        //         alert(com[1].replace(":", "").replace(">", ""));
        if (com[1].replace(":", "").replace(">", "") in commands) {
          commands[com[1].replace(":", "").replace(">", "")](com[2].trim());
          this.com = com[1];
          this.command = "";
          this.propositions = null;
          if (this.skip) {
            this.com = "";
          }
          this.continue();
        }
      }
    },
    sendInteraction() {
      let command;
      if (this.command.match(/(.+)>c>(.*)/)) {
        command = this.command.match(/(.+)>c>(.*)/);
        if (command.length > 0) {
          console.log(
            "1",
            this.speaker.find((item) => item.text == command[1]).actions.c
          );

          this.interaction = this.speaker.find(
            (item) => item.text == command[1]
          ).actions.c[command[2]];
          this.com = "";
          this.command = "";
          this.$store.commit("waitForMo");
          this.propositions = null;
          this.continue();
        }
      } else if (this.command.match(/(.+)>([n,b,r,d,s,z])/)) {
        command = this.command.match(/(.+)>([n,b,r,d,s,z])/);
        if (command.length > 0) {
          this.interaction = this.speaker.find(
            (item) => item.text == command[1]
          ).actions[command[2]];
          this.com = "";
          this.command = "";
          this.$store.commit("waitForMo");
          this.propositions = null;
          this.continue();
        }
      }
    },
    wait(c) {
      if (c.match(this.re)) {
        let com = c.match(this.re);
        if (com[2] != "") {
          this.tips[0].custom = com[2];
        }
        //this.$store.commit("load");
        //this.propositions = [{ text: ">continue" }];
        //this.checkCommand();
      }
      this.$store.commit("load");
      this.propositions = [{ text: ">continue" }];
      this.checkCommand();
    },
    continue() {
      if (this.com == ">continue" && this.loading) {
        this.$store.commit("loaded");
        //this.$store.dispatch('setMusic', this.currentMusic)
      }
      this.$store.commit("skip", false);
      if (
        this.com == ">say:" ||
        this.anydialogue != null ||
        this.com == ">talk:" ||
        this.com == ">add:"
      ) {
        this.command = ">say:";
        this.checkCommand();
      } else {
        //TODO: actions
        //  this.propositions = [...this.propositions_default];
        this.propositions = this.waitingForMo
          ? [{ text: ">continue" }]
          : this.speaker;
        this.checkCommand();
      }
      this.com = "";
    },
    showPropositions() {
      this.showProp = true;
    },
    hidePropositions() {
      this.showProp = false;
    },
    checkCommand() {
      const self = this;
      if (this.command.match(this.re)) {
        let com = this.command.match(this.re);
        let search = com[2].trim();

        const commands = {
          say(c) {
            return self.anydialogue.options
              .filter(self.isShown)
              .filter((d) =>
                self.getText(d.text).toLowerCase().includes(c.toLowerCase())
              );
          },
          talk(c) {
            return self.dialogues.filter((d) =>
              d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          take(c) {
            return self.items.filter(
              (d) =>
                d.inMantu == false &&
                d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          remove(c) {
            return self.items.filter(
              (d) =>
                d.inMantu == true &&
                d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          set(c) {
            return Object.values(self.conditions).filter(
              (d) =>
                d.check == false &&
                d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          unset(c) {
            return Object.values(self.conditions).filter(
              (d) =>
                d.check == true &&
                d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          upgrade(c) {
            return self.items.filter(
              (d) =>
                (("v" in d && d.v < d.img.length - 1) ||
                  (d.img.constructor === Array && !("v" in d))) &&
                d.text.toLowerCase().includes(c.toLowerCase())
            );
          },
          add(c) {
            let p = [{ text: ">back" }, { text: ">add: " + c }];
            if (self.subdialogue != null) {
              p.push(
                { text: ">add return: " + c },
                { text: ">add resolve: " + c }
              );
            }
            return p;
          },
          wait(c) {
            let p = [{ text: ">wait:" + c }];
            return p;
          },
        };
        if (this.isTalking) {
          this.command = "";
          this.propositions = [{ text: ">continue" }];
        } else if (this.isBusy) {
          this.command = "";
          this.propositions = [{ text: "" }, { text: ">skip" }];
        } else if (com[1].replace(":", "").replace(">", "") in commands) {
          let result =
            commands[com[1].replace(":", "").replace(">", "")](search);
          if (result.length > 0) {
            this.propositions = result;
            if (com[1] == ">say:" || this.anydialogue != null) {
              this.propositions.push({ text: ">exit" });
              if (com[1] == ">say:") {
                this.propositions.push({ text: ">add:" });
              }
            }
          } else {
            //TODO: change
            this.propositions = [...this.propositions_default];
            this.currentItem = 0;
          }
        }
      } else if (this.anydialogue != null) {
        this.command = ">say:";
        this.checkCommand();
      } else if (this.loading) {
        this.command = "";
        this.propositions = [{ text: ">continue" }];
      } else if (this.command.charAt(0) == ">") {
        this.propositions = [...this.propositions_default];
        this.propositions = this.propositions.filter((d) =>
          self
            .getText(d.text)
            .toLowerCase()
            .includes(this.command.toLowerCase())
        );
      } else {
        if (!this.command.includes(">")) {
          this.propositions = this.waitingForMo
            ? [{ text: ">continue" }]
            : this.speaker;

          this.propositions = this.propositions.filter((d) =>
            self
              .getText(d.text)
              .toLowerCase()
              .includes(this.command.toLowerCase())
          );
        } else if (this.command.match(/(.+)>c>(.*)/i)) {
          //combine
          this.propositions = Object.keys(
            this.speaker.find(
              (i) => i.text == self.command.match(/(.+)>c>(.*)/i)[1]
            ).actions.c
          );
          this.propositions = this.propositions.filter((d) =>
            d
              .toLowerCase()
              .includes(this.command.match(/(.+)>c>(.*)/i)[2].toLowerCase())
          );
        } else {
          //interaction
          this.propositions = this.interactions;
          this.propositions = this.propositions.filter((d) =>
            self
              .getText(d.text)
              .toLowerCase()
              .includes(this.command.match(/>(.*)/i)[1].toLowerCase())
          );
        }
      }
    },
    setCommand(command) {
      const self = this;
      let result = this.command.match(this.re);
      if (
        command.text.constructor != Array &&
        command.text.match(/(>>clear)/i)
      ) {
        this.command = "";
      } else if (
        command.text.constructor != Array &&
        command.text.match(/(.+)>([n,b,r,d,s,z])/i)
      ) {
        this.command = command.text;
        this.sendInteraction();
      } else if (
        // combine
        command.text.constructor != Array &&
        command.text.match(/(.+)>([c])>(.+)/i)
      ) {
        this.command = command.text;
        this.sendInteraction();
      } else if (command.text == ">exit") {
        //TODO: combine next three
        this.command = command.text;
        this.sendCommand();
      } else if (command.text == ">skip") {
        this.command = command.text;
        this.sendCommand();
      } else if (command.text == ">continue") {
        this.command = command.text;
        this.sendCommand();
      } else if (command.text == ">add:") {
        this.propositions = [{ text: ">back" }];
        this.command = command.text;
      } else if (result) {
        this.command = result[1] + " " + self.getText(command.text);
        this.sendCommand();
        //  this.propositions = null;
      } else {
        console.log("command:", command.text);
        this.command = command.text;
        if (
          this.command.charAt(0) != ">" &&
          this.command.charAt(this.command.length - 1) != ">"
        ) {
          this.command += ">";
        }
        this.checkCommand();
      }
    },
    say(c) {
      const self = this;
      let options = this.anydialogue.options.filter(this.isShown);
      let option = options.find(
        (o) => self.getText(o.text).toLowerCase() == c.toLowerCase()
      );
      let id = options.findIndex(
        (o) =>
          self.getText(o.text).toLowerCase() == self.getText(c).toLowerCase()
      );
      if (option && id != undefined) {
        this.$refs._dialogue.ChooseOption(option, id);
      }
    },
    talk(c) {
      let dialogue = this.dialogues.find(
        (d) => d.text.toLowerCase() == c.toLowerCase()
      );
      if (dialogue) {
        this.$refs._dialogue.setDialogue(dialogue);
      }
    },
    take(c) {
      let item = this.items.find(
        (i) => i.text.toLowerCase() == c.toLowerCase()
      );
      if (item) {
        this.$refs._inventory.takeItem(item);
      }
      this.continue();
    },
    remove(c) {
      let item = this.items.find(
        (i) => i.text.toLowerCase() == c.toLowerCase()
      );
      if (item) {
        this.$refs._inventory.removeItem(item);
      }
      this.continue();
    },
    set(c) {
      let condition = Object.values(this.conditions).findIndex(
        (con) => con.text.toLowerCase() == c.toLowerCase()
      );
      if (condition != undefined) {
        condition++;
        this.$store.commit("condition", { id: condition, bool: true });
      }
      this.continue();
    },
    unset(c) {
      let condition = Object.values(this.conditions).findIndex(
        (con) => con.text.toLowerCase() == c.toLowerCase()
      );
      if (condition != undefined) {
        condition++;
        this.$store.commit("condition", { id: condition, bool: false });
      }
      this.continue();
    },
    upgrade(c) {
      let item = this.items.find(
        (i) => i.text.toLowerCase() == c.toLowerCase()
      );
      if ("v" in item) {
        item.v++;
      } else {
        item.v = 1;
      }
      if (item.inMantu) {
        this.$refs._inventory.removeItem(item);
        this.$refs._inventory.takeItem(item);
      } else {
        this.$refs._inventory.takeItem(item);
        this.$refs._inventory.removeItem(item);
      }
      this.continue();
    },
    add(o) {
      if (o == ">back") {
        this.continue();
      } else {
        this.$refs._dialogue.addOption(o);
        this.continue();
      }
    },
    nextItem() {
      if (event.keyCode == 13) {
        let command;
        if (this.command.includes(">") && this.command.charAt(0) != ">") {
          if (this.command.match(/(.+)>c>(.*)/i)) {
            console.log("prop", this.propositions[this.currentItem]);
            command = {
              text:
                this.command.match(/(.+>c>)(.*)/i)[1] +
                this.propositions[this.currentItem],
            };
          } else {
            command = {
              text:
                this.command.match(/(.+>)/i)[1] +
                this.propositions[this.currentItem].action,
            };
          }
        } else {
          command = this.propositions[this.currentItem];
        }
        this.setCommand(command);
        this.currentItem = 0;
      }
      if (event.keyCode == 38 && this.currentItem > 0) {
        this.currentItem--;
      } else if (
        event.keyCode == 40 &&
        this.currentItem < this.propositions.length - 1
      ) {
        this.currentItem++;
      }
    },
    getText(text) {
      if (text.constructor === Array) {
        return text[0];
      }
      return text;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";

h3 {
  margin: $margin-mid 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: $margin-default;
  cursor: pointer;
}
li:hover {
  //  font-weight: bold;
}
div.options {
  margin-top: $margin-default;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: flex-start;
}
div.selectOption {
  margin: $margin-small $margin-default;
}

span.option {
  cursor: pointer;
  font-weight: bold;
  color: white;
  min-width: 100px;
  display: inline-block;
  padding: 0px $margin-small;
}
span.option:hover {
  background: white;
  color: black;
}
a {
  color: #42b983;
}
$font: $margin-default * 2;
#private {
  position: absolute;
  width: $width;
  height: $height-full;
  top: $height-full;
  display: flex;
  flex-direction: column;
  background: rgb(53, 53, 53);
  font-size: $font;
  overflow: scroll;
}
#console,
#speaker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.console {
  font-size: $margin-mid;
  font-family: "Courier New", Courier, monospace;
  color: white;
}
$padding_c: 8 * $border;
$width_c: $width * 0.4;
input.console {
  display: block;
  width: $width_c;
  height: $margin-mid;
  font-size: $margin-mid;
  padding: $padding_c;
  font-family: "Courier New", Courier, monospace;
  margin: $margin-mid 0;
  background: black;
  color: white;
}
input:focus {
  outline: none;
}
input {
  border: $border solid rgb(77, 77, 77);
  border-radius: $dialoguebox-padding;
}
.row {
  display: flex;
  flex-direction: row;
}
#dialogues {
  text-align: left;
  width: 50%;
  color: white;
}
#conditions {
  width: 50%;
}
.conditions {
  width: 100%;
}
#inventory {
  width: 100%;
}
#public {
  width: 100%;
  height: 50%;
  background: transparent;
  cursor: none;
}
.public {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.name {
  position: absolute;
  display: list-item;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.active {
  color: #353535;
  background: white;
}
</style>
