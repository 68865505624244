<template>
  <div id="tabs">
    <div
      class="conditions tab"
      :class="{ active: loading }"
      @click="toggleLoading"
    >
      Loading screen
    </div>
    <div
      v-for="(chapter, id) in chapters"
      v-bind:key="id"
      class="conditions tab"
      :class="{ active: chapter.active }"
      @click="changeChapter(id)"
    >
      {{ chapter.text }}
    </div>
    <div
      class="conditions tab"
      :class="{ active: arrow_default }"
      @click="setArrow('r')"
    >
      Pfeil ein/aus
    </div>
    <div
      class="conditions tab"
      :class="{ active: !muted }"
      @click="toggleMute()"
    >
      Mute Böttn
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tabs",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "loading",
      "chapters",
      "currentMusic",
      "arrow_r",
      "arrow_default",
      "muted",
    ]),
  },
  mounted() {
    //  document.addEventListener("keydown", this.nextItem);
  },
  methods: {
    toggleLoading() {
      this.loading ? this.loaded() : this.load();
    },
    load() {
      this.$store.commit("load");
      //  this.$store.dispatch('setMusic', 'loading.mp3')
    },
    loaded() {
      this.$store.commit("loaded");
      //  this.$store.dispatch('setMusic', this.currentMusic)
    },
    changeChapter(id) {
      this.$store.commit("setChapter", id);
      this.$parent.continue();
    },
    setArrow() {
      this.$store.commit("toggleArrow");
      this.$store.commit("toggleArrowDefault");
    },
    toggleMute() {
      this.$store.commit("toggleMute");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";
@import "../scss/_shared_private.scss";
$width_t: 8 * $margin-mid;
.tab {
  max-width: $width_t;
  text-align: center;
  margin-right: 2px;
  cursor: pointer;
}
#tabs {
  display: flex;
}
</style>
