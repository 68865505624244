export const setCondition = {
  methods: {
    setCondition(conditions) {
      for (let condition of conditions) {
        this.$store.commit("condition", {
          id: condition[0],
          bool: condition[1],
        });
        //    this.conditions[condition[0]].check = condition[1];
      }
    },
  },
};
