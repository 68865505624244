<template>
  <div id="app">
    <div id="momou">
      <Install v-if="!installed" />
      <Public v-if="installed" />
      <Private />
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Private from "./components/private.vue";
import Public from "./components/public.vue";
import Install from "./components/install.vue";

export default {
  name: "App",
  components: {
    Public,
    Private,
    Install,
  },
  mounted() {
    if ("install" in this.$route.query) {
      this.$store.commit("startInstall");
      this.$store.commit("setTimer", this.$route.query.install);
    }
  },
  computed: {
    ...mapGetters([
      "dialogues",
      "conditions",
      "currentDialogue",
      "anydialogue",
      "talkingTo",
      "selected",
      "installed",
      // ...
    ]),
  },
};
</script>

<style lang="scss">
@import "./scss/_variables.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //  text-align: center;
  background: transparent;
}
html,
body {
  background: transparent;
  margin: 0;
}
$heigth_m: $height-full * 2;
#momou {
  height: $heigth_m;
  width: $width;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.cover {
  position: absolute;
  height: $height;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.waiting {
  height: $height-waiting;
  width: auto;
  filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 1))
    drop-shadow(-3px -3px 0px rgba(255, 255, 255, 1))
    drop-shadow(1px 5px 5px rgba(0, 0, 0, 0.55));
  /*filter: drop-shadow(4px 4px 0px rgba(229,0,126,1)) drop-shadow(-4px -4px 0px rgba(229,0,126,1)) drop-shadow(1px 3px 5px rgba(0,0,0,0.55));*/
  /*    filter: drop-shadow(1px 3px 5px rgba(0,0,0,0.55));*/
}
</style>
