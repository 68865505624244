<template>
  <div id="public">
    <Waiting v-if="loading" />
    <div class="arrow" v-if="arrow_r">
      <img class="arrow" src="../assets/inventory/arrow.gif" />
    </div>
    <!--  <div class="name" v-if="anydialogue != null || talkingTo != null">
      {{ talkingTo }}
    </div>-->
    <div id="video" v-if="video">
      <video class="video" muted :src="getVideo(true)"></video>
      <video
        class="video"
        loop
        autoplay
        muted
        :src="getVideo(true)"
        v-if="!isBusy"
      ></video>
      <video
        class="video"
        autoplay
        muted
        :src="getVideo()"
        v-if="isBusy"
      ></video>
    </div>
    <div id="event" v-if="event">
      <video
        class="video"
        autoplay
        :src="getEvent()"
        @ended="eventEnded"
      ></video>
    </div>
    <div id="menu">
      <div id="controls">
        <div class="block" v-if="!anydialogue">
          <h1>Nimm</h1>
          <h1>Rede</h1>
          <h1>Schau</h1>
        </div>
        <div class="block" v-if="!anydialogue">
          <h1>Benutze</h1>
          <h1>Drücke</h1>
          <h1>Ziehe</h1>
        </div>
        <div id="inventory" v-if="!anydialogue">
          <div class="slot" v-for="index in 12" :key="index">
            <img
              class="item"
              v-if="inventory[index - 1] != undefined"
              :src="getUrl(index - 1)"
            />
          </div>
        </div>
        <div id="dialogue" v-else>
          <ul v-if="anydialogue.options.filter(isShown)">
            <li
              v-for="(option, idx) in anydialogue.options.filter(isShown)"
              :key="idx"
            >
              <div
                class="dialoguebox"
                v-bind:class="{
                  selected: selected == idx,
                  hide: selected != idx && selected != null,
                }"
              >
                > {{ getText(option.text) }}
              </div>
            </li>
          </ul>

          <img v-if="polaroid" id="polaroid" :src="polaroid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Waiting from "./waiting.vue";
import { isShown } from "../mixins/isShown.js";
import { getText } from "../mixins/getText.js";
import { mapGetters } from "vuex";
export default {
  name: "Public",
  mixins: [isShown, getText],
  components: { Waiting },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "dialogues",
      "conditions",
      "currentDialogue",
      "anydialogue",
      "talkingTo",
      "selected",
      "inventory",
      "loading",
      "video",
      "event",
      "idle",
      "isBusy",
      "arrow_r",
    ]),
    polaroid() {
      if ("img" in this.currentDialogue) {
        return require("../assets/polaroid/" + this.currentDialogue.img);
      }
      return false;
    },
  },
  methods: {
    getUrl(id) {
      let file;
      if (this.inventory[id].img.constructor === Array) {
        if ("v" in this.inventory[id]) {
          file = this.inventory[id].img[this.inventory[id].v];
        } else {
          file = this.inventory[id].img[0];
        }
      } else {
        file = this.inventory[id].img;
      }
      return require("../assets/inventory/" + file);
    },
    getVideo(idle = false) {
      return idle
        ? require("../assets/audio/" + this.idle)
        : require("../assets/audio/" + this.video);
    },
    getEvent() {
      return require("../assets/videos/" + this.event);
    },
    eventEnded() {
      this.$store.commit("stopVideo");
      this.$store.commit("toggleMute");
    },
    getPolaroid(id) {
      let file;
      if (this.inventory[id].img.constructor === Array) {
        if ("v" in this.inventory[id]) {
          file = this.inventory[id].img[this.inventory[id].v];
        } else {
          file = this.inventory[id].img[0];
        }
      } else {
        file = this.inventory[id].img;
      }
      return require("../assets/inventory/" + file);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: margin-default;
  cursor: pointer;
}
#public {
  width: $width;
  height: $height;
  background: transparent;
  cursor: none;
  font-family: lores-15, sans-serif;
  font-weight: 700;
  font-style: normal;
}

h1 {
  font-size: $button-text;
  font-family: lores-15, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-style: normal;
  color: #d0d0d0;
  margin: $margin-small;
}
.public {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.name {
  position: absolute;
  display: list-item;
  width: calc(900px * 2);
  color: #e5007e !important;
  //  background: #312700;
  background-image: url("../assets/background2.jpg");
  background-size: cover;
  padding: calc(5px * 2);
  padding: calc(10px * 2) calc(30px * 2);
  font-weight: bold;
  color: white;
  font-size: 16px;
}
#menu {
  //  background: #312700;
  background-image: url("../assets/background2.jpg");
  background-size: cover;
  height: $menu-height;
  width: $width;
  position: absolute;
  //  width: 100%;
  top: $height;
}
#controls {
  display: flex;
  flex-direction: row;
  padding: 0px $margin-big;
}
#inventory {
  //  height: 100%;

  height: $inventory-height;
  //  width: calc(100% - 10px);
  width: $inventory-width;
  margin: 0px 0 0px $margin-mid;
  border: solid $border #4a4f37;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: rgba(49, 27, 27, 0.85);
  //background: rgb(49, 18, 31);
}
#polaroid {
  //  background: #e5007e;
  //  width: 200px;
  width: auto;

  height: $polaroid-height;
  position: absolute;
  right: $polaroid-right;
  bottom: $polaroid-bottom;
  transform: rotate(4deg);
}
.slot {
  height: $slot-heigth;
  //width: calc((100% / 7) - 2.5px);
  width: $slot-width;
  padding: $padding-slot;
  border: solid $border #4a4f37;
}
.item {
  width: 100%;
  height: 100%;
  /*    -webkit-filter: drop-shadow(3px 3px 0 #e5007e)
                  drop-shadow(-3px -3px 0 #e5007e);
  filter: drop-shadow(3px 3px 0 #e5007e) 
          drop-shadow(-3px -3px 0 #e5007e);*/
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 1))
    drop-shadow(-1px -1px 0px rgba(255, 255, 255, 1));
}
.block {
  padding: $margin-default $margin-small;
}
.options {
  width: 500px;
}
.hide {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.dialoguebox {
  display: inline-block;
  //  background: rgba(205, 134, 63, 0.5);
  padding: 0 $dialoguebox-padding 0 $dialoguebox-padding;
  font-weight: bold;
  color: white;
  font-size: $dialogue;
  opacity: 100;
  transition: background 50ms ease-out;
}
.selected {
  //  background: rgba(255, 0, 255, 0.5);
  color: #e5007e;
  transition: background 200ms ease-out;
}
#video,
#event {
  width: $width;
  height: $height;
}
.video {
  top: 0;
  position: absolute;
  width: $width;
  height: $height;
}
$arrow: $margin-default * 4;
$arrowtop: $height / 2 - 2 * $margin-default;

div.arrow {
  top: $arrowtop;
  width: $arrow;
  height: $arrow;
  right: $margin-big;
  position: absolute;
}
img.arrow {
  width: $arrow;
  height: auto;
}
</style>
