export const isShown = {
  //TODO: to store
  methods: {
    isShown(option) {
      if ("count" in option) {
        if (option.count < 1) {
          return false;
        }
      }
      if ("exeption" in option) {
        /*for (let c of option.exeption) {
          if (this.conditions[c[0]].check == c[1]) {
            return true;
          }
        }*/
        if (this.exeption(option.exeption)) return true;
      }
      if ("condition" in option) {
        //  let check = this.exeption(option.condition)
        //  if (check) return check;
        for (let c of option.condition) {
          if (this.conditions[c[0]].check != c[1]) {
            return false;
          }
        }
      }
      return true;
    },
    exeption(conditions) {
      for (let c of conditions) {
        if (this.conditions[c[0]].check != c[1]) {
          return false;
        }
      }
      return true;
    },
  },
};
