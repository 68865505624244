<template>
  <div id="speaker">
    <span class="text active">
      {{ text }}
    </span>
    <!--    <div v-for="(item, id) in speaker" v-bind:key="id">
      {{ item.text }}
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setCondition } from "../mixins/setCondition";

export default {
  //TODO: Events an Conditions knüpfen
  //TODO: Audiodateien erlauben

  name: "Speaker",
  mixins: [setCondition],
  components: {},
  props: {
    interaction: Object,
  },
  data() {
    return {
      c: false,
      e: false,
      a: false,
      currentText: 0,
      isArray: false,
      //  speakerText: "test",
    };
  },
  computed: {
    ...mapGetters([
      "conditions",
      "speaker",
      "waitingForMo",
      "events",
      "event",
      // ...
    ]),
    text() {
      if ("c0" in this.interaction && this.waitingForMo) {
        let i = this.checkConditions();
        return this.checkActions(i);
        //    this.getText();
        //    return this.speakerText;
      }
      return "";
    },
  },
  mounted() {},
  methods: {
    /*    getText() {
      let i = this.checkConditions();
      this.speakerText = this.checkActions(i);
    },*/
    checkConditions() {
      for (let c in this.interaction) {
        let condition = c.replace("c", "");
        if (condition > 0) {
          if (this.conditions[condition].check == true) {
            return this.interaction["c" + condition];
          }
        }
      }
      return this.interaction.c0;
    },
    checkActions(interaction) {
      this.c = false;
      this.e = false;
      this.a = false;

      let i = interaction;
      if (interaction.constructor === Array) {
        this.isArray = true;
        i = interaction[this.currentText];
      } else {
        this.isArray = false;
      }
      if (i.constructor === Object) {
        if ("setCondition" in i) {
          this.c = i.setCondition;
        }
        if ("startEvent" in i) {
          this.e = i.startEvent;
        }
        if ("action" in i) {
          this.a = i.action;
        }
        return i.text;
      }
      return i;
    },
    continue() {
      let wait = false;
      if (this.c) {
        this.setCondition(this.c);
      }
      if (this.a) {
        let command = { text: this.a };
        this.$parent.setCommand({ text: ">>clear" });
        this.$parent.setCommand(command);
        this.$parent.sendCommand();
      }
      if (this.e) {
        this.$store.commit("playEvent", this.events[this.e].video);
        this.$store.commit("mute");
        if ("wait" in this.events[this.e]) {
          if (this.events[this.e].wait) {
            this.$parent.setCommand({ text: ">>clear" });
            this.$parent.setCommand({ text: ">wait:" });
            this.$parent.sendCommand();
            this.$store.commit("skip", true);
            wait = true;
          }
        }
      }
      let i = this.checkConditions();
      if (this.isArray && i.length > this.currentText + 1) {
        this.currentText++;
      } else {
        this.currentText = 0;
        this.$store.commit("continue");
      }
      if (!wait) {
        this.$parent.continue();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.active {
  color: #353535;
  background: white;
}
.text {
  //    color: white;
}
</style>
