<template>
  <div id="inventory">
    <div v-for="(i, idx) in items" :key="idx">
      <div
        class="item"
        v-bind:class="{ active: i.inMantu }"
        @click="moveItem(i)"
      >
        {{ i.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { setCondition } from "../mixins/setCondition.js";
import { mapGetters } from "vuex";
export default {
  name: "Inventory",
  components: {},
  mixins: [setCondition],
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "conditions",
      "selected",
      "items",
      "inventory",
      // ...
    ]),
  },
  methods: {
    moveItem(item) {
      item.inMantu ? this.removeItem(item) : this.takeItem(item);
    },
    takeItem(item) {
      if ("setCondition" in item) {
        if ("in" in item.setCondition) {
          this.setCondition(item.setCondition.in);
        }
      }
      this.continue(item);
    },
    removeItem(item) {
      if ("setCondition" in item) {
        if ("out" in item.setCondition) {
          this.setCondition(item.setCondition.out);
        }
      }
      this.continue(item);
    },
    continue(item) {
      if ("isCondition" in item) {
        this.setCondition([[item.isCondition, !item.inMantu]]);
      } else {
        item.inMantu = !item.inMantu;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../scss/_variables.scss";

#inventory {
  margin-top: $margin-mid;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
$box: $margin-small * 20;
.item {
  width: $box;
  height: $box;
  background: rgb(245, 131, 116);
  font-weight: bold;
  color: #353535;
  cursor: pointer;
  margin: $margin-small;
}
@import "../scss/_shared_private.scss";
</style>
