var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"private"}},[_c('Tabs'),_c('div',{attrs:{"id":"console"}},[_c('div',{staticClass:"console",on:{"keydown":_vm.nextItem}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.command),expression:"command"}],staticClass:"console",attrs:{"type":"text"},domProps:{"value":(_vm.command)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.command=$event.target.value},_vm.checkCommand],"focus":_vm.showPropositions,"focusout":_vm.hidePropositions}}),(_vm.showProp)?_c('ul',_vm._l((_vm.propositions),function(proposition,idx){return _c('li',{key:idx,ref:{ active: _vm.currentItem === idx },refInFor:true,class:{ active: _vm.currentItem === idx },on:{"click":function($event){return _vm.setCommand(proposition)}}},[_vm._v(" "+_vm._s(_vm.writeProposition(proposition))+" ")])}),0):_vm._e()])]),_c('div',{attrs:{"id":"speaker"}},[_c('Speaker',{ref:"_speaker",attrs:{"interaction":_vm.interaction}})],1),_c('div',{staticClass:"row"},[_c('Dialogue',{ref:"_dialogue"}),_c('div',{attrs:{"id":"conditions"}},[_c('Conditions',{ref:"_conditions",attrs:{"_conditions":Object.values(_vm.conditions)
            .filter(_vm.filterImportantConditions)
            .sort(function (a, b) {
              var textA = a.text.toUpperCase();
              var textB = b.text.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })}})],1)],1),_c('div',{attrs:{"id":"inventory"}},[_c('Inventory',{ref:"_inventory"})],1),_c('div',{staticClass:"conditions"},[_c('Conditions',{ref:"_conditions",attrs:{"_conditions":Object.values(_vm.conditions)
          .filter(_vm.filterConditions)
          .sort(function (a, b) {
            var textA = a.text.toUpperCase();
            var textB = b.text.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }